const background = document.querySelector('.background')
const rightContainer = document.querySelector('.right ')

document.addEventListener("turbolinks:load", () => {
    if (background) { background.classList.remove('d-none') }
    if (rightContainer) { rightContainer.classList.remove('d-none') }

    setTimeout(removeBackground, 1800)
    setTimeout(hideMobileSubtitle, 3600)

    if (document.querySelector('.profile-item')) {
        handleSubtitle()
        loadCards()
    }
})

function removeBackground() {
    const background = document.querySelector('.background')
    if (background) {
        background.style.width = '0px'
        background.style.opacity = 0
    }
}

function hideMobileSubtitle() {
    const rightContainer = document.querySelector('.right ')
    if (rightContainer && window.innerWidth < 678) {
        rightContainer.style.opacity = 0
        setTimeout(removeMobileSubtitle, 3000)
    }
}

function removeMobileSubtitle() {
    const rightContainer = document.querySelector('.right ')
    rightContainer.classList.add('d-none')
}


function handleSubtitle() {
    const profileList = document.querySelector('.profile-list')
    const subTitle = document.querySelector('.sub-title')

    profileList.addEventListener('mouseover', () => {
        subTitle.style.opacity = 0
    })

    profileList.addEventListener('mouseout', () => {
        subTitle.style.opacity = 1
    })
}


function loadCards() {
    const profileNames = document.querySelectorAll('.profile-item')

    profileNames.forEach(profile => {
        profile.addEventListener('mouseover', () => {
            profile.querySelector('.card-loader').click();
        })
    })
}
